import React from 'react';
import {classes, st} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {LineItemRow} from './LineItem/LineItem';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {SecureCheckout} from './SecureCheckout/SecureCheckout';
import {Text, TextButton} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {SlotId} from '../../../domain/utils/slotId';
import {CheckoutSlot} from '../CheckoutSlot';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {StepId, SummaryLocation, ViolationsAndPlaceOrderButtonLocation} from '../../../types/app.types';
import {ViolationsAndPlaceOrderButton} from '../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {FoldableSummarySection} from './FoldableSummarySection/FoldableSummarySection';

export enum SummaryDataHooks {
  LineItemSection = 'SummaryDataHooks.LineItemSection',
  titleText = 'SummaryDataHooks.title',
  editCartButton = 'SummaryDataHooks.editCartButton',
  couponSectionInput = 'SummaryDataHooks.couponSectionInput',
  giftCardSectionInput = 'SummaryDataHooks.giftCardSectionInput',
  numberOfOrders = 'SummaryDataHooks.numberOfOrders',
  violations = 'SummaryDataHooks.violations',
}

export interface SummaryProps {
  location: SummaryLocation;
  dataHook: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Summary = ({dataHook, location}: SummaryProps) => {
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {editCartUrl, clickOnEditCart},
    checkoutStore: {checkout, shouldShowGiftCardSection, shouldShowCouponSection, isFastFlow},
    stepsManagerStore: {activeStep},
    isOneColumnView,
  } = useControllerProps();
  const {isMobile} = useEnvironment();
  const {experiments} = useExperiments();

  const shouldShowViolationsAndPlaceOrderButton =
    isFastFlow || activeStep.stepId === StepId.placeOrder || activeStep.stepId === StepId.paymentAndPlaceOrder;

  const addSlotToCheckout = !isFastFlow && experiments.enabled(SPECS.AddSlotToCheckout);

  let className = classes.sticky;

  if (experiments.enabled(SPECS.MergePaymentAndPlaceOrderSteps) && !isFastFlow) {
    className = location === SummaryLocation.paymentAndPlaceOrderStep ? classes.rootMobile : classes.root;
  }

  if (
    experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) &&
    !isFastFlow &&
    location === SummaryLocation.paymentAndPlaceOrderStep
  ) {
    className = classes.fullFlowMobile;
  }

  const shouldSupportNewMobileSummaryBehavior =
    experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) && isOneColumnView;
  return (
    <>
      {shouldSupportNewMobileSummaryBehavior && <FoldableSummarySection />}
      <div className={className} data-hook={dataHook}>
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.BeforeSummaryCheckoutSlot} />
        )}
        <div className={classes.summary}>
          <div className={classes.title}>
            <div className={classes.header}>
              <Text tagName={'h2'} className={classes.titleText} data-hook={SummaryDataHooks.titleText}>
                {localeKeys.checkout.order_summary.$value()}
              </Text>{' '}
              <Text
                data-hook={SummaryDataHooks.numberOfOrders}
                className={classes.numberOfOrders}
                aria-label={localeKeys.checkout.orderSummary.itemAmount_ariaLabel({
                  itemNumber: checkout.numberOfItems,
                })}>
                ({checkout.numberOfItems})
              </Text>
            </div>
            {checkout.cartId && (
              <div className={classes.editCartButtonWrapper}>
                <TextButton
                  as={'a'}
                  href={editCartUrl}
                  onClick={() => clickOnEditCart()}
                  className={classes.editCartButton}
                  data-hook={SummaryDataHooks.editCartButton}>
                  {localeKeys.checkout.edit_cart()}
                </TextButton>
              </div>
            )}
          </div>
          <section
            data-hook={SummaryDataHooks.LineItemSection}
            aria-label={localeKeys.checkout.orderSummary.fullSection.ariaLabel()}>
            <ul className={st(classes.itemsList, isMobile ? classes.itemsListMobile : undefined)}>
              {checkout.lineItems &&
                checkout.lineItems.map((lineItem) => (
                  <LineItemRow
                    lineItem={lineItem}
                    key={lineItem.id}
                    useLayoutForLongPrice={checkout.hasItemsWithLongPrice}
                  />
                ))}
            </ul>
          </section>
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterLineItemsSummaryCheckoutSlot} />
          )}
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterLineItems2SummaryCheckoutSlot} />
          )}
          <div className={classes.couponAndGiftCard}>
            {shouldShowCouponSection && <CouponInput />}
            {shouldShowGiftCardSection && <GiftCardInput />}
          </div>
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot
              slotId={SlotId.BeforeTotalsBreakdownSummaryCheckoutSlot}
            />
          )}
          <TotalsSection />
          {shouldShowViolationsAndPlaceOrderButton && (
            <ViolationsAndPlaceOrderButton location={ViolationsAndPlaceOrderButtonLocation.summary} />
          )}
        </div>
        <SecureCheckout />
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterSummaryCheckoutSlot} />
        )}
      </div>
    </>
  );
};
